$(document).ready(function () {

  var
      btnCloseAlert = $(".close-alert")
  ;

  btnCloseAlert.on("click", function (e) {
      var
          elem = $(e.currentTarget),
          parent = elem.parent(),
          bodyParent = parent.parent()
      ;
      if( bodyParent.hasClass("alert-body") ) {
          bodyParent.remove();
      }
      if( parent.hasClass("alert") ) {
        parent.remove();
      }
  });


});