if( !window.initMap ) {
    window.initMap = function () {

        var

            locations = [
                {
                    title: "Comité Organisateur des écoles Institut Saint-Ferdinand",
                    position: {lat:50.4467207, lng:3.8821279},
                },
                {
                    title: "Implantation de Jemappes M1 à P2",
                    position: {lat:50.4467207, lng:3.8821279},
                },
                {
                    title: "Implantation de Jemappes P3 à P6",
                    position: {lat:50.4466885, lng:3.8808993},
                },
                {
                    title: "Implantation de Flénu",
                    position: {lat:50.4367113, lng:3.8862354},
                },
                {
                    title: "Implantation de Cuesmes",
                    position: {lat:50.4384032, lng:3.9221551},
                },
            ],

            markers = [],

            center = { lat: 50.4391254, lng: 3.8818114 },

            map = new google.maps.Map(document.getElementById("map-markers"), {
                center: center,
                zoom: 12,
            })
        ;

        locations.forEach(function (location, i) {
            markers.push(new google.maps.Marker({
                position: location.position,
                map,
                title: location.title,
            }));
        });

    }
}