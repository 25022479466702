///////////////////////////////////////////////////////////////
// This script will be responsible of handling the datatables
///////////////////////////////////////////////////////////////


// //////////////////////////////////////////////// GENERIC DATABLE CONFIG

var globalDatableOptions = {
	responsive: true,
	pageLength: 50,
	paging: false,
	searching: true,
	bInfo: false,
	autoWidth: false,
	colReorder: true,
	dom: 'Bfrtip',
	language: {
		sSearch: "Search&nbsp;:", zeroRecords: "No results",
	},
	drawCallback: function( settings ) { /* This function hide/show the pagination bar when pages >/= 1 */
		if($(this).DataTable().page.info().pages > 1){
			$(this).parent().find('.dataTables_paginate').show();
		}else{
			$(this).parent().find('.dataTables_paginate').hide();
		}
	},
};


$(document).ready(function() {

	// //////////////////////////////////////////////// BASIC DATATABLE INITIALIZATION
	window.sampleBasicDatableVar = $('#sampleBasicDatable').DataTable(Object.assign({}, globalDatableOptions, {
		buttons: [{
			text: '<i class="fa fa-plus m-r-5"></i> Open modal 1',
			action: function (e, dt, button, config) { $('#generic-modal').modal('show') }
		}],
		aoColumnDefs: [{
			bSortable: false, aTargets: [1, 2, 3]
		}]
	}));

	// //////////////////////////////////////////////// AJAX DATATABLE BUTTON BINDING
	$('#sampleAjaxDatableButtonLoader').click(function()
	{
		if (!window.sampleAjaxDatableVar)
		{
			window.sampleAjaxDatableVar = $('#sampleAjaxDatable').DataTable(Object.assign({}, globalDatableOptions, {
				buttons: [],
				aoColumnDefs: [{
					bSortable: false, aTargets: [1, 2]
				}],
				ajax: { url: Routing.generate('ajaxTableList'), dataSrc: "", dataType: "JSON" }
			}));
		}
		else window.sampleAjaxDatableVar.ajax.reload();
	});




	/** PREVENT FILTERS TO TRIGGER SORTING **/
	$('.input-filter input, .select-filter select').on('click', function(e){
		e.stopPropagation();
	});


});

/** SCROLL TO THE TOP WHEN THE PAGE CHANGE **/
$(document).on('click', '.paginate_button', function () {
	let table = $('#' + $(this).attr('aria-controls'));
	let top = table.offset().top - 100;
	setTimeout(function(){
		$([document.documentElement, document.body]).animate({
			scrollTop: top
		}, 200);
	}, 500);
});
