
// Show the filename in the Boostrap design of the file input
$(document).on('change', '.custom-file-input', function(){
	var fileName = $(this).val();
	$(this).next('.custom-file-label').html(fileName);
});

// ENTER key will trigger click
$(document).on('keyup', '[data-enter]', function (e) {
	if(e.which === 13) {
		$('#' + $(this).data('enter')).trigger('click');
	}
});

$(document).ready(function() {

	///////////////////////////////////////////////////////////////////// SAMPLE ROUTING MANAGEMENT

	$('#homeJsBtn').click(function () { window.location = Routing.generate('front_home');  });
	$('#sampleJsBtn').click(function () { window.location = Routing.generate('front_sample'); });


	///////////////////////////////////////////////////////////////////// SAMPLE Confirm modal

	$('#sample_delete').on('click', function () {
		Swal.fire({
			title: 'Êtes vous sûr ?',
			text: 'Une fois supprimé, il ne sera plus possible de récupérer ce fichier imaginaire !',
			icon: 'warning',
			showCancelButton: true,
			showCloseButton: true,
			confirmButtonText: 'Oui, je n\'en veux plus !',
			cancelButtonText: 'Non, garde le.'
		}).then((result) => {
			console.log(result.dismiss);
			if (result.value) {
				Swal.fire(
					'Supprimé !',
					'Votre fichier imaginaire a bien été supprimé.',
					'success'
				)
			} else if (result.dismiss === Swal.DismissReason.cancel) {
				console.log('Annulé avec le bouton "Non, garde le."');
			} else if (result.dismiss === Swal.DismissReason.close) {
				console.log('Annulé avec la croix');
			} else if (result.dismiss === Swal.DismissReason.esc) {
				console.log('Annulé avec touche ESC');
			} else if (result.dismiss === Swal.DismissReason.backdrop) {
				console.log('Annulé avec click derrière');
			} else if (result.dismiss === Swal.DismissReason.backdrop) {
				console.log('Annulé par le timer');
			}
		});
	});

	///////////////////////////////////////////////////////////////////// BTN UP
	$('.btn-up').click(function(e){
		e.preventDefault();
		$('html,body').animate({ scrollTop : 0 },500);
	});


	///////////////////////////////////////////////////////////////////// CHECK EMAIL VALIDITY

	isEmail = function isEmail(email) {
		var regex = /^([a-zA-Z0-9_.+-])+\@(([a-zA-Z0-9-])+\.)+([a-zA-Z0-9]{2,6})+$/;
		return regex.test(email);
	};

	///////////////////////////////////////////////////////////////////// TOOLTIP
	$('[data-toggle="tooltip"]').tooltip();
	$(document).on('focus', '[data-toggle="tooltip"]', function () { // prevent some issues
		$(this).blur();
	});


	///////////////////////////////////////////////////////////////////// CROSS BROWSER PLACEHOLDER
	$('[placeholder]').focus(function() {
		var input = $(this);
	  if (input.val() == input.attr('placeholder')) {
			input.val('');
			input.removeClass('placeholder');
			if(input.hasClass('password')){
				input.get(0).type='password';
			}
	  }
	}).blur(function() {
		var input = $(this);
	  if (input.val() == '' || input.val() == input.attr('placeholder')) {
			input.addClass('placeholder');
			input.val(input.attr('placeholder'));
			if(input.hasClass('password')){
				input.get(0).type='text';
			}
	  }
	}).blur();

	$('[placeholder]').parents('form').submit(function() {
		$(this).find('[placeholder]').each(function() {
			var input = $(this);
			if (input.val() == input.attr('placeholder')) {
				input.val('');
			}
	  })
	});



	///////////////////////////////////////////////////////////////////// LIGHT SLIDER

	var sliderPubli = $("#publications-recentes-slider").lightSlider({
		item: 3,
		loop: true,
		slideMove: 1,
		controls: false,
		pager: false,
		easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
		speed: 600,
		responsive: [{
			breakpoint: 1180,
			settings: {
				item: 3,
				slideMove: 1
			}
		}, {
			breakpoint: 960,
			settings: {
				item: 2,
				slideMove: 1
			}
		}, {
			breakpoint: 570,
			settings: {
				item: 1,
				slideMove: 1
			}
		}]
	});
	$('#PrevSlidePubli').on('click', function () {
		sliderPubli.goToPrevSlide();
	});
	$('#NextSlidePubli').on('click', function () {
		sliderPubli.goToNextSlide();
	});

	var sliderAlbum = $("#albums-recents-slider").lightSlider({
		item: 3,
		loop: true,
		slideMove: 1,
		controls: false,
		pager: false,
		easing: 'cubic-bezier(0.25, 0, 0.25, 1)',
		speed: 600,
		responsive: [{
			breakpoint: 1180,
			settings: {
				item: 3,
				slideMove: 1
			}
		}, {
			breakpoint: 960,
			settings: {
				item: 2,
				slideMove: 1
			}
		}, {
			breakpoint: 570,
			settings: {
				item: 1,
				slideMove: 1
			}
		}]
	});
	$('#PrevSlideAlbum').on('click', function () {
		sliderAlbum.goToPrevSlide();
	});
	$('#NextSlideAlbum').on('click', function () {
		sliderAlbum.goToNextSlide();
	});



	///////////////////////////////////////////////////////////////////// DOTTDOTDOT

	$('.card-publication-dotwrapper').dotdotdot({
		ellipsis: ' ... ',
		wrap: 'word',
		fallbackToLetter: true,
		after: null,
		watch: true,
		height: 100,
		tolerance: 0,
		callback: function (isTruncated, orgContent) { },
		lastCharacter: {
			remove: [' ', ',', ';', '.', '!', '?'],
			noEllipsis: []
		}
	});

	$('.card-publication-dotwrapper-2').dotdotdot({
		ellipsis: ' ... ',
		wrap: 'word',
		fallbackToLetter: true,
		after: null,
		watch: true,
		height: 50,
		tolerance: 0,
		callback: function (isTruncated, orgContent) { },
		lastCharacter: {
			remove: [' ', ',', ';', '.', '!', '?'],
			noEllipsis: []
		}
	});

	$('.card-album-dotwrapper').dotdotdot({
		ellipsis: ' ... ',
		wrap: 'word',
		fallbackToLetter: true,
		after: null,
		watch: true,
		height: 100,
		tolerance: 0,
		callback: function (isTruncated, orgContent) { },
		lastCharacter: {
			remove: [' ', ',', ';', '.', '!', '?'],
			noEllipsis: []
		}
	});




	///////////////////////////////////////////////////////////////////// ???
	loading = function loading(element, active) {
		let isAlreadyActive = $(element).find('.loading').length > 0;
		if (typeof active === "undefined") active = !isAlreadyActive;
		if (active && !isAlreadyActive) {
			$(element)
				.css('position', 'relative')
				.append($(
					'<div class="loading" style="position: absolute; width: 100%; height: 100%; z-index: 1060; background: rgba(255,255,255,0.7); left: 0; top: 0; text-align: center; vertical-align: middle;">' +
					'<i class="fa fa-spin fa-circle-o-notch" style="font-size: 30px;"></i>' +
					'</div>'
				));
			let fontSize = Math.ceil(($(element).height() > $(element).width() ? $(element).width() : $(element).height()) / 4);
			if(fontSize < 10) fontSize = 10;
			$(element).find('.loading i')
				.css('margin-top', Math.ceil(($(element).height() - fontSize) / 2) + 'px')
				.css('font-size', fontSize + 'px')
				.css('color', '#000000');
		} else if (!active && isAlreadyActive) {
			setTimeout(function () {
				$(element).find('.loading').remove();
			}, 200);
		}
	};


	//Addthis elements
	if(addthis) {
		$(".addthis_inline_share_toolbox").each(function (k, elem) {
			elem = $(elem);
			let
				url = elem.data('url'),
				title = elem.data('title'),
				description = elem.data('description'),
				media = elem.data('media')
			;
			if(url) addthis.update('share', 'url', url);
			if(title) addthis.update('share', 'title', title);
			if(description) addthis.update('share', 'description', description);
			if(media) addthis.update('share', 'media', media);
		});
	}

	$("#modal-newsletter").on("hidden.bs.modal", function (e) {
		var
			form = $(e.currentTarget).find("form"),
			fieldText = form.find("input[type=text]"),
			fieldsImplantations = form.find(".field-implantation-checkbox")
		;
		form.find(".alert-form-error").remove();
		fieldText.val("");
		fieldsImplantations.prop("checked", false);
	});

	$("#modal-newsletter").find("form").on("submit", function (e) {
		var
			form = $(e.currentTarget),
			fieldEmail = form.find(".field-email"),
			fieldsImplantations = form.find(".field-implantation-checkbox:checked"),
			alertError = $("<div class='alert alert-danger alert-form-error'></div>")
		;
		form.find(".alert-form-error").remove();
		if(!fieldEmail.val()) alertError.append("<p><i class='fa fa-warning'></i> <span>L'adresse e-mail est requise.</span></p>");
		else if(!isEmail(fieldEmail.val())) alertError.append("<p><i class='fa fa-warning'></i> <span>L'adresse e-mail est invalide.</span></p>");

		if(!fieldsImplantations.length) alertError.append("<p><i class='fa fa-warning'></i> <span>Vous devez sélectionner une ou plusieurs implantation(s)</span></p>");
		if(alertError.text()) {
			form.find(".modal-body").prepend(alertError);
			return false;
		}
		return true;
	});

});
