// Need jQuery? Install it with "yarn add jquery", then uncomment to require it.
// const $ = require('jquery');
const $ = window.$ = window.jQuery = require('jquery');

const Swal = swal = require('sweetalert2');
window.Swal = window.swal = Swal;

require('lightbox2');
require('bootstrap');
require('lightslider');
require('jquery.dotdotdot');
require('datatables');
require('datatables.net-buttons');
require('datatables.net-responsive');

require('./javascript.js');
require('./dt.js');
require('./google.map');
require('../../js/alert.flash');
